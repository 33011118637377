@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  font-family: Roboto, sans-serif;

  .ant-modal-root .ant-modal-confirm {
    .ant-modal-content {
      background-color: #edf1d6;

      .ant-btn-default {
        background-color: #edf1d6;
        border-color: #d4de9d;
        color: rgba(0, 0, 0, 0.88);
      }

      .ant-btn-primary {
        color: #fff;
        background-color: #9dc08b;
      }

      .ant-btn-default:hover {
        color: #2a421f;
        border-color: #2a421f;
      }

      .ant-btn-primary:hover {
        color: #fff;
        background-color: #2a421f;
      }
    }
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #707070;
}

::-webkit-scrollbar-thumb:hover {
  background: #355cc9;
}
